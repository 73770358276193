import React, { useState, useEffect } from "react";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, FormGroup, FormControlLabel, TextField as MuiTextField } from "@mui/material";
import { Checkbox, TextField } from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import AlertMain from "components/common/AlertMain";
import useOrderViewFunctions from "./commonFunctions/useOrderViewFunctions";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import SearchLocation from "screens/CustomOrders/GoogleMapsAutocomplete";

const EditOrderView = ({ editData, setStatus, status, open, setOpen, updateOrders, setUpdateOrders, onUpdateSuccess, setRefreshData, refreshData }) => {

    const { todayDate, alert, changeStatus, validationSchema, handleClose, setpickUpDateValue, setdeliveryDateValue, setSpecialCareDate, cngPickupDelivery, waitPDdate, pickUpDateValue, oldPickUpDateValue, deliveryDateValue, oldDeliveryDateValue, specialCareDate, oldSpecialCareDate, setAlert } = useOrderViewFunctions({ editData, setStatus, status, open, setOpen, setUpdateOrders });

    const axios = useAxiosPrivate();
    const [zipCode, setZipCode] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [pickupTimeSlot, setPickupTimeSlot] = useState("");
    const [diliverTimeSlot, setDeliverTimeSlot] = useState("");
    const [orderType, setOrderType] = useState(null);
    const [pickupNote, setPickupNote] = useState("");
    const [pickupDate, setPickupDate] = useState("");
    const [diliverDate, setDiliverDate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [deliveryNote, setDeliveryNote] = useState("");
    const [orderNote, setOrderNote] = useState("");

    const [openConfirmation, setOpenConfirmation] = useState(false); // State for confirmation dialog
    const [pendingSaveValues, setPendingSaveValues] = useState(null); // State to hold values until confirmed

    useEffect(() => {

        const formatDate = (dateString) => {
            if (!dateString) return "";

            // Parse the date string into a Date object
            const date = new Date(dateString);

            if (isNaN(date.getTime())) {
                console.log("Invalid date:", dateString);
                return "";
            }

            // Extract year, month, and day
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
            const day = String(date.getDate()).padStart(2, '0');

            // Return the date in YYYY-MM-DD format
            return `${year}-${month}-${day}`;
        };

        setPickupTimeSlot(editData.pickupTimeSlot ? editData.pickupTimeSlot : "");
        setDeliverTimeSlot(editData.diliverTimeSlot ? editData.diliverTimeSlot : "");
        setOrderType(editData.orderType ? editData.orderType : []);
        setPickupNote(editData.pickupNote ? editData.pickupNote : "");
        setDeliveryNote(editData.deliveryNote ? editData.deliveryNote : "");
        setOrderNote(editData.orderNote ? editData.orderNote : "");
        setPickupDate(editData.pickupDate ? formatDate(editData.pickupDate) : "");
        setDiliverDate(editData.diliverDate ? formatDate(editData.diliverDate) : "");
        setDeliveryDate(editData.deliveryDate ? formatDate(editData.deliveryDate) : "");
    }, [editData]);

    console.log("editData", editData);

    useEffect(() => {
        // Function to convert time slots into 24-hour format
        const extractTimeSlot = (timeSlot) => {
            if (!timeSlot) return ["", ""]; // Return empty strings if no time slot is provided
            return timeSlot.split(" - ").map((time) => {
                const [timeString, modifier] = time.split(" ");
                let [hours, minutes] = timeString.split(":");
                if (modifier === "PM" && hours !== "12") {
                    hours = String(parseInt(hours, 10) + 12);
                }
                if (modifier === "AM" && hours === "12") {
                    hours = "00";
                }
                return `${hours.padStart(2, "0")}:${minutes}`;
            });
        };

        if (editData) {
            if (editData?.pickupTimeSlot) {
                const [start, end] = extractTimeSlot(editData.pickupTimeSlot);
                setStartTime(start);
                setEndTime(end);
            }

            if (editData?.diliverTimeSlot) {
                const [deliveryStart, deliveryEnd] = extractTimeSlot(editData.diliverTimeSlot);
                setStartTime(deliveryStart);
                setEndTime(deliveryEnd);
            }
        }
    }, [editData]);

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
    };

    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
    };

    const handleChange3 = (event) => {
        setChecked3(event.target.checked);
    };

    // Compute the orderType based on the checkbox states
    function computeOrderType() {
        if (checked1 && !checked2 && !checked3) return 1;
        else if (!checked1 && checked2 && !checked3) return 2;
        else if (!checked1 && !checked2 && checked3) return 3;
        else if (!checked1 && checked2 && checked3) return 4;
        else if (checked1 && !checked2 && checked3) return 5;
        else if (checked1 && checked2 && !checked3) return 7;
        else if (checked1 && checked2 && checked3) return 9;
        else return 0; // Default case when nothing is selected
    }

    // Set initial checkbox states based on editData.orderType
    useEffect(() => {
        if (editData?.orderType) {
            const orderType = parseInt(editData.orderType, 10);

            switch (orderType) {
                case 1:
                    setChecked1(true);  // Wash/Dry/Fold
                    setChecked2(false);
                    setChecked3(false);
                    break;
                case 2:
                    setChecked1(false);
                    setChecked2(true);  // Dry Cleaning
                    setChecked3(false);
                    break;
                case 3:
                    setChecked1(false);
                    setChecked2(false);
                    setChecked3(true);  // Tailoring
                    break;
                case 4:
                    setChecked1(false);
                    setChecked2(true);  // Dry Cleaning
                    setChecked3(true);   // Tailoring
                    break;
                case 5:
                case 6:
                    setChecked1(true);   // Wash/Dry/Fold
                    setChecked2(false);
                    setChecked3(true);   // Tailoring
                    break;
                case 7:
                case 8:
                    setChecked1(true);   // Wash/Dry/Fold
                    setChecked2(true);   // Dry Cleaning
                    setChecked3(false);
                    break;
                case 9:
                case 10:
                    setChecked1(true);   // Wash/Dry/Fold
                    setChecked2(true);   // Dry Cleaning
                    setChecked3(true);   // Tailoring
                    break;
                default:
                    setChecked1(false);  // Default: Uncheck all
                    setChecked2(false);
                    setChecked3(false);
                    break;
            }
            setOrderType(orderType); // Set the initial orderType from editData
        }
    }, [editData?.orderType]);  // Run this effect when editData.orderType is available or changes

    // Update the orderType whenever checkboxes change
    useEffect(() => {
        const newOrderType = computeOrderType();
        setOrderType(newOrderType); // Update the orderType state
    }, [checked1, checked2, checked3]);  // Run this effect whenever any checkbox state changes

    const handleStartTimeChange = (e) => {
        const newStartTime = e.target.value;
        setStartTime(newStartTime);
        updatePickupTimeSlot(newStartTime, endTime);
    };

    const handleEndTimeChange = (e) => {
        const newEndTime = e.target.value;
        setEndTime(newEndTime);
        updatePickupTimeSlot(startTime, newEndTime);
    };

    const updatePickupTimeSlot = (start, end) => {
        const formattedStartTime = formatTime(start);
        const formattedEndTime = formatTime(end);
        pickupTimeSlot ? setPickupTimeSlot(`${formattedStartTime} - ${formattedEndTime}`) : setDeliverTimeSlot(`${formattedStartTime} - ${formattedEndTime}`)
    };

    const formatTime = (time) => {
        const [hours, minutes] = time.split(":");
        const formattedHours = parseInt(hours, 10) % 12 || 12;
        const modifier = parseInt(hours, 10) >= 12 ? "PM" : "AM";
        return `${formattedHours}:${minutes} ${modifier}`;
    };

    // Function to handle Save, but opens the confirmation dialog first
    const handleSave = (values) => {
        // Store the form values in state until confirmed
        setPendingSaveValues(values);
        setOpenConfirmation(true); // Open confirmation dialog
    };

    // Function that confirms the save action
    const confirmSave = async () => {
        const { address, location, ...remainingValues } = pendingSaveValues; // Use the stored values in pendingSaveValues

        console.log("pendingSaveValues", pendingSaveValues);

        let formattedDeliveryDate;
        let formattedPickupDate;
        let formattedDeliveryDate2;

        const dateOptions = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            timeZone: 'UTC'
        };

        if (pickupDate) {
            // Format pickupDate as "Friday, September 20, 2024"
            formattedPickupDate = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(pickupDate));
        }

        if (diliverDate) {
            formattedDeliveryDate = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(diliverDate));
        }

        if (diliverDate) {
            // Format diliverDate as ISO 8601 format "2024-10-09T00:00:00.000Z"
            formattedDeliveryDate2 = new Date(diliverDate).toISOString();
        }

        let updatedOrder = {
            ...remainingValues,
            orderNote,
            orderType,
            orderAddress: pendingSaveValues.address
                ? pendingSaveValues.address
                : (pendingSaveValues.orderAddress ? pendingSaveValues.orderAddress : "no address"),
            orderLocation: pendingSaveValues.location
                ? pendingSaveValues.location
                : (pendingSaveValues.orderLocation ? pendingSaveValues.orderLocation : "no location"),
            ...(pickupTimeSlot ? { pickupTimeSlot } : { diliverTimeSlot }) // Dynamically add either pickupTimeSlot or deliveryTimeSlot
        };

        // Conditionally include/exclude pickupDate and deliveryDate
        if (pickupDate) {
            updatedOrder = {
                ...updatedOrder,
                pickupDate: formattedPickupDate,
                pickupNote // Include pickupNote if available
            };
        } else if (diliverDate) {
            updatedOrder = {
                ...updatedOrder,
                diliverDate: formattedDeliveryDate,
                deliveryDate: formattedDeliveryDate2,
                deliveryNote // Include deliveryNote if available
            };
        }

        console.log("updatedOrder", updatedOrder);


        try {
            const response = await axios.put(`order/updateorder/${pendingSaveValues.id}`, updatedOrder);
            onUpdateSuccess();
            console.log("Order updated successfully", response.data);
            setAlert({
                showAlert: true,
                severity: 'success',
                message: 'Order Updated Successfully',
            });
            setRefreshData(!refreshData);
            setUpdateOrders(!updateOrders)
            setOpen(!open);
            setTimeout(() => {
                setAlert({
                    showAlert: false,
                    severity: 'success',
                    message: '',
                });
            }, 3000);
        } catch (error) {
            console.error("Error updating order", error);
            if (error.response.data) {
                setTimeout(() => {
                    setAlert({
                        showAlert: true,
                        severity: 'error',
                        message: error.response.data.message,
                    });
                }, 3000);
            }
            setRefreshData(!refreshData);
        }

        // Close the confirmation dialog after saving
        setOpenConfirmation(false);
    };

    // Function to cancel the save action
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false); // Close confirmation dialog without saving
    };



    return (
        <>
            <Dialog fullWidth maxWidth={editData?.isManualOrder ? "sm" : "lg"} open={open} onClose={handleClose}>
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h5" align="right" color="primary"> {editData?.orderStatus === "process" ? "processing" : editData?.orderStatus} </Typography>
                    {editData?.isManualOrder && <Typography style={{ fontWeight: "bold", fontSize: "1rem", textAlign: "center" }} mt={{ lg: 0, md: 0, sm: 0, xs: 3, }} mb={1} align="center">Manual Order</Typography>}
                    <Typography variant="h5" mt={{ lg: 0, md: 0, sm: 0, xs: 3, }} align="center">{`Order ID: ${editData.orderId}`}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Formik initialValues={editData} validationSchema={validationSchema} enableReinitialize>
                        {({ isValid, values, setFieldValue, dirty }) => (
                            <Form>
                                <Grid container item mt={2} justifyContent="center">
                                    <Grid container item lg={editData?.isManualOrder ? 10 : 5} md={6} sm={12} xs={12} mt={2} rowSpacing={2}>
                                        <Grid item lg={10} md={10} sm={12} xs={12}>
                                            <TextField fullWidth variant="outlined" disabled={true} label={(sessionStorage.getItem("role") === "corporate" || (sessionStorage.getItem("managerType") === "corporate" && sessionStorage.getItem("role") === "manager")) ? "Corporate Business Name" : "Franchisee Business Name"} value={`${editData?.franchiseeId?.fullName}`} />
                                        </Grid>
                                        <Grid item lg={10} md={10} sm={12} xs={12}>
                                            <TextField fullWidth variant="outlined" disabled={true} label="Customer Name" value={`${editData?.customer?.firstName} ${editData?.customer?.lastName}`} />
                                        </Grid>
                                        <Grid item lg={10} md={10} sm={12} xs={12}>
                                            <TextField fullWidth variant="outlined" disabled={true} label="Email Address" value={editData?.customer?.email} />
                                        </Grid>
                                        <Grid container spacing={2} my={1}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <label style={{ color: "rgba(0, 0, 0, 0.54)", padding: "0", fontSize: "1rem", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: "400", lineHeight: "1", letterSpacing: "0.00938em" }}> {editData.pickupTimeSlot ? "Pickup " : "Delivery "}Start Time:</label>
                                                <Field
                                                    as={MuiTextField}
                                                    name="startTime"
                                                    type="time"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    value={startTime}
                                                    onChange={handleStartTimeChange}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <label style={{ color: "rgba(0, 0, 0, 0.54)", padding: "0", fontSize: "1rem", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: "400", lineHeight: "1", letterSpacing: "0.00938em" }}>{editData.pickupTimeSlot ? "Pickup " : "Delivery "}End Time:</label>
                                                <Field
                                                    as={MuiTextField}
                                                    name="endTime"
                                                    type="time"
                                                    variant="outlined"
                                                    InputProps={{ inputProps: { min: startTime } }}
                                                    fullWidth
                                                    required
                                                    value={endTime}
                                                    onChange={handleEndTimeChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <SearchLocation setFieldValue={setFieldValue} values={values} setZipCode={setZipCode} dirty={dirty} isValid={isValid} />
                                        </Grid>
                                        {editData?.pickupDate && (<Grid item lg={10} md={10} sm={12} xs={12}>
                                            <TextField name="pickupDate" label={`PickUp Date`} value={pickupDate} onChange={(e) => setPickupDate(e.target.value)} type="date" InputProps={{ inputProps: { min: `${todayDate}` } }} />
                                        </Grid>)}
                                        {editData?.diliverDate && (<Grid item lg={10} md={10} sm={12} xs={12}>
                                            <TextField name="deliveryDate" label={`Delivery Date`} value={diliverDate} onChange={(e) => setDiliverDate(e.target.value)} type="date" InputProps={{ inputProps: { min: `${todayDate}` } }} />
                                        </Grid>)}
                                        {editData?.specialDate && (
                                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                                <TextField name="specialDate" label={`Special care date`} value={specialCareDate} onChange={(newValue) => setSpecialCareDate(newValue.target.value)} type="date" InputProps={{ inputProps: { min: `${todayDate}` } }} />
                                            </Grid>
                                        )}
                                        {(editData?.deliveryNote || editData?.pickupNote || editData?.orderNote) && editData?.orderType && (
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={checked1} onChange={handleChange1} />}
                                                        label="Wash/Dry/Fold"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={checked2} onChange={handleChange2} />}
                                                        label="Dry Cleaning"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={checked3} onChange={handleChange3} />}
                                                        label="Tailoring"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        )}
                                        {(editData?.deliveryNote || editData?.pickupNote || editData?.orderNote) && <Grid container item spacing={2} xs={12} md={12} xl={12} lg={12}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}><Typography>Instructions</Typography></Grid>
                                            {editData?.pickupNote && <Grid item lg={10} md={10} sm={12} xs={12}>
                                                <TextField fullWidth multiline maxRows={4} variant="outlined" label="Pickup Instructions" value={pickupNote} onChange={(e) => setPickupNote(e.target.value)} />
                                            </Grid>}
                                            {editData?.orderNote && <Grid item lg={10} md={10} sm={12} xs={12}>
                                                <TextField fullWidth multiline maxRows={4} variant="outlined" label="Laundry Instructions" value={orderNote} onChange={(e) => setOrderNote(e.target.value)} />
                                            </Grid>
                                            }
                                            {editData?.deliveryNote && <Grid item lg={10} md={10} sm={12} xs={12}>
                                                <TextField fullWidth multiline maxRows={4} variant="outlined" label="Delivery Instructions" value={deliveryNote} onChange={(e) => setDeliveryNote(e.target.value)} />
                                            </Grid>}
                                        </Grid>}
                                        {/* <Grid container item spacing={1} lg={"auto"} md={"auto"} sm={12} xs={12}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <Button name="pending" variant="contained" color="primary" fullWidth onClick={() => changeStatus("pending")} disabled={editData?.orderStatus === "pending" || editData?.orderStatus === "process" || editData?.orderStatus === "completed"}> Pending </Button>
                                            </Grid>
                                            <Grid item xs={12} md={4} xl={4}>
                                                <Button data-testid="processing-btn" name="process" variant="contained" color="primary" fullWidth onClick={() => changeStatus("process")} disabled={editData?.orderStatus === "process" || editData?.orderStatus === "completed"}> Processing </Button>
                                            </Grid>
                                            <Grid item xs={12} md={4} xl={4}>
                                                <Button data-testid="completed-btn" name="completed" variant="contained" color="primary" fullWidth onClick={() => changeStatus("completed")} disabled={(editData?.orderStatus === "completed" || backendCallWeight || backendCall)}> Completed </Button>
                                            </Grid>
                                        </Grid> */}
                                        <Grid item lg={10} md={10} sm={12} xs={12}>
                                            <Button type="button" variant="contained" color="primary" onClick={() => handleSave(values)}>
                                                Save
                                            </Button>
                                        </Grid>

                                        {/* Confirmation Dialog */}
                                        <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                                            <DialogTitle>Confirm Save</DialogTitle>
                                            <DialogContent>
                                                <Typography variant="body1">This action will clear the in-progress route build. Are you sure you want to edit this order?</Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseConfirmation}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={confirmSave} color="primary">
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                    </Grid>
                                </Grid>
                                <AlertMain setAlert={setAlert} alert={alert} />
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions><Button data-testid="close-btn" autoFocus onClick={handleClose} color="primary"> Close </Button></DialogActions>
            </Dialog>
        </>
    );
}

export default EditOrderView;


