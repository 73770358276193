import React, { useState, useEffect, useContext, useMemo } from "react";
import Timeline, { TodayMarker, CursorMarker, DateHeader } from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import { JobContext } from "../../screens/Routing/jobContext";
import moment from "moment";
import { Button, Card, CardContent, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setJobs, setTimelineSelected } from "../../../src/store/actions/RouteAction";
import { makeStyles } from '@material-ui/core/styles';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import SwapItemsModal from "./SwapItemsModal";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const useStyles = makeStyles((theme) => ({
  timelineWidth: {
    marginTop: "0.4rem",
    width: "100rem",
  },
}));

const timeSetter = (d, date) => {
  const hour = d?.split(":")[0];
  const minutes = d?.split(":")[1];
  if (hour === "24" || hour === "00") {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, minutes);
  } else {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minutes);
  }
};

const timeSplitterNew = (timeFrame, date) => {
  if (timeFrame === undefined) return { start_time: '', end_time: '' };
  const getHours = (val) => moment(val, ["h:mm A"]).format("HH:mm");
  const start = getHours(timeFrame?.split(" - ")[0]);
  const end = getHours(timeFrame?.split(" - ")[1]);
  return {
    start_time: timeSetter(start, date),
    end_time: timeSetter(end, date)
  }
};

const convertTime = (time) => new Date(time).toLocaleString('en-US', { timeStyle: "short", hour12: true });


export default function TimelineFunction({ value, setTimeSlotState, selectedOrderMap, setSelectedOrderMap, setJobItems }) {

  const envStartDate = (process.env.REACT_APP_START_DATE).split(",").map(item => Number(item))
  const envEndDate = (process.env.REACT_APP_END_DATE).split(",").map(item => Number(item))

  const classes = useStyles();
  const axios = useAxiosPrivate();

  let dispatch = useDispatch();
  const franchiseeTimeZone = "GMT"
  const startDate = useMemo(() => new Date(value?.setHours(envStartDate[0], envStartDate[1], envStartDate[2], envStartDate[3])).getTime(), [value, envStartDate]);
  const endDate = useMemo(() => new Date(value?.setHours(envEndDate[0], envEndDate[1], envEndDate[2], envEndDate[3])).getTime(), [value, envEndDate]);
  const serviceTime = useSelector((state) => state.route.serviceTime) ?? sessionStorage.getItem("serviceTime");
  const [vehiclesInfo, setVehiclesInfo] = useState(null)

  // context api
  // eslint-disable-next-line
  const { newJob, vehicles, shared } = useContext(JobContext);

  // redux
  let jobs = useSelector((state) => state.route.jobs);
  const [items, setItems] = useState([]);
  const [mainUpdatedItems, setMainUpdatedItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [unassignedCount, setUnassignedCount] = useState(0);

  useEffect(() => {
    sessionStorage.setItem('selecetdOrderTimeline', selected?.id)
  }, [selected])

  useEffect(() => {

    const getVehicals = async () => {
      const { data } = await axios.get("/vehicle");
      let vehicles = [];
      for (const v of data) {
        if (v?.driver) vehicles.push(v)
      }
      setVehiclesInfo(vehicles)
    }

    getVehicals();

  }, [])

  const groups = useMemo(() => {
    const dataGroups = [];

    //build and dispatched
    if ((shared && vehicles?.length > 0)) {
      for (const vehicle of vehicles) {
        const startTimeMap = moment.tz(vehicle?.routeSettings?.workingStartTime, `${franchiseeTimeZone}`).format("HH:mm");
        const endTimeMap = moment.tz(vehicle?.routeSettings?.workingEndTime, `${franchiseeTimeZone}`).format("HH:mm");

        dataGroups.push({
          id: vehicle?.id,
          title: `🚙${vehicle?.name} 🧑${vehicle?.driver?.name}
          🕑${startTimeMap} to ${endTimeMap} `,
          color: vehicle.color,
          tip: "test",
          stackItems: false
        });
      }
    }

    //build but not dispatched
    else if (!shared && vehicles?.length > 0 && jobs?.length > 0) {
      let vehicleList = vehicles?.filter(item => item?.routeSettings?.availability === "available" && item?.driver)
      for (const vehicle of vehicleList) {
        const startTimeMap = moment.tz(vehicle?.routeSettings?.workingStartTime, `${franchiseeTimeZone}`).format("HH:mm");
        const endTimeMap = moment.tz(vehicle?.routeSettings?.workingEndTime, `${franchiseeTimeZone}`).format("HH:mm");
        dataGroups.push({
          id: vehicle?.id,
          title: `🚙${vehicle?.name} 🧑${vehicle?.driver?.name}
          🕑${startTimeMap} to ${endTimeMap} `,
          color: vehicle.color,
          tip: "test",
          stackItems: false
        });
      }
      if (!shared) {
        dataGroups.push({ id: 'unassigned', title: `unassigned (${unassignedCount})`, tip: 'test', stackItems: false });
      }
    } else {
      //not build and not dispatched
      if (vehiclesInfo && vehiclesInfo.length > 0) {
        for (const vehicle of vehiclesInfo) {
          dataGroups.push({
            id: vehicle?.id,
            title: `🚙${vehicle?.name} 🧑${vehicle?.driver?.name}`,
            color: vehicle.color,
            tip: "test",
            stackItems: false
          });
        }
      } else {
        dataGroups.push({ id: 'unassigned', title: `unassigned (${unassignedCount})`, tip: 'test', stackItems: false });
      }

    } return dataGroups;
  }, [vehicles, jobs, shared, items, unassignedCount, vehiclesInfo])

  const updateJobs = (id, vehicleId, start_time, end_time, timeGap, groupType) => {
    let color = '#000000';
    let time = end_time;
    let address = '';

    if (vehicleId === 'unassigned') {
      if (jobs?.length > 0) {
        const temp = jobs.map(job => {
          if (job?.id === id) {
            address = job?.orderID[0]?.orderAddress
            const itemProps = {
              // ...job.itemProps,
              style: {
                background: color
              }
            };
            return { ...job, vehicle: null, itemProps: itemProps, vehicleID: 'unassigned', service_time: null };
          } else {
            return job;
          }
        })
        dispatch(setJobs(temp));
      } else if (newJob?.length > 0) {
        const temp = newJob.find(job => job?.id === id);
        address = temp?.address;
      }
    } else {
      if (jobs?.length > 0) {
        const vehicle = vehicles?.filter(item => item?.routeSettings?.availability === "available" && item?.driver).find(item => item?.id === vehicleId);
        color = vehicle?.color;
        const service_time_vehicle = vehicle?.routeSettings?.speed;
        const temp = jobs.map(job => {
          if (job?.id === id) {
            address = job?.orderID[0]?.orderAddress
            const itemProps = {
              // ...job.itemProps,
              style: {
                background: color
              }
            };
            // time = Number(start_time) + (service_time_vehicle ? service_time_vehicle * 60000 : 600000); // default 10 minutes
            //use session storage to get franchisee service time and check if selected timeline item is unassigned one
            // Number(serviceTime) *1000 - convert seconds in to milliseconds
            time = Number(start_time) + (groupType === "unassigned" ? Number(serviceTime) * 1000 : timeGap); // default 10 minutes
            const timeFrame = convertTime(start_time) + ' - ' + convertTime(time);
            return { ...job, vehicle, itemProps, timeFrame, vehicleID: vehicleId, service_time: service_time_vehicle ? service_time_vehicle : 10, };
          } else {
            return job;
          }
        })
        dispatch(setJobs(temp));
      }
    }
    return { color, time, address };
  };

  const setData = () => {
    if (newJob?.length === 0 && (jobs?.length > 0)) {
      const data = jobs?.map((job) => {
        let start_time = 0;
        let end_time = 0;
        if (job?.vehicleID === 'unassigned') {
          const times = timeSplitterNew(job?.timeFrame, value);
          start_time = times.start_time;
          end_time = times.end_time;
        } else {
          start_time = job?.start_time;
          end_time = job?.end_time;
        } return {
          id: job?.id, // id of the item
          address: job?.orderID[0]?.orderAddress,
          group: job?.vehicleID,
          title: "",
          extraTitle: job?.orderID[0]?.orderId,
          canMove: true,
          canResize: true,
          timeSlot: job.orderID[0].pickupTimeSlot ? job?.orderID[0]?.pickupTimeSlot : job?.orderID[0]?.diliverTimeSlot,
          start_time,
          end_time,
          customerName: job?.customerName,
          type: job?.type,
          itemProps: {
            'data-custom-attribute': job?.orderID[0]?.orderId,
            'aria-hidden': true,
            style: {
              background: job?.vehicle?.color ?? '#000000'
            }
          },
        };
      });
      setItems(data);
      setJobItems(data)
      updateItemsCount(data)

      // Create time slot state array
      const timeSlotData = data.map((item) => ({
        extraTitle: item.extraTitle,
        start_time: item.start_time,
        end_time: item.end_time,
      }));

      // Update time slot state with new array
      setTimeSlotState(timeSlotData);

    } else {
      // eslint-disable-next-line
      setItems(newJob);
      setJobItems(newJob)
      setUnassignedCount(0)
    }
  };

  const [mapOrder, setMapOrder] = useState(null);

  useEffect(() => {
    setMapOrder(selectedOrderMap)
  }, [selectedOrderMap])

  const itemRenderer = ({ item, itemContext, getItemProps }) => {

    const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.itemProps.style.background) : item.itemProps.style.background;

    return (
      <div
        {...getItemProps({
          style: {
            background: backgroundColor,
            borderStyle: "solid",
            borderWidth: itemContext.selected ? 3 : 1,
          },
        })}
      >
      </div>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (items?.length > 0) {
      const unassignedItems = items.filter((i) => i?.group === "unassigned");
      setUnassignedCount(unassignedItems.length)
    }
  }, [items, jobs])

  const updateItemsCount = (item) => {
    if (item?.length > 0) {
      const unassignedItems = item.filter((i) => i?.group === "unassigned");
      setUnassignedCount(unassignedItems.length)
    }
  }


  useEffect(() => {
    setSelected(null);
    dispatch(setTimelineSelected(null));
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newJob])

  useEffect(() => {
    if (jobs?.length > 0) {
      setSelected(null);
      dispatch(setTimelineSelected(null));
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (jobs?.length > 0) {
      setSelected(null);
      dispatch(setTimelineSelected(null));
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  // Function to move single item from one group to another
  const itemMover = (itemId, dragTime, newGroupOrder) => {
    const newItems = items.map((item) => {
      if (item?.id === itemId) {
        const timeGap = item.end_time - item.start_time
        const start_time = dragTime;
        const end_time = dragTime + (item.end_time - item.start_time);
        const group = groups[newGroupOrder].id;
        const { color, time, address } = updateJobs(itemId, group, start_time, end_time, timeGap, item.group);
        setSelected({ ...selected, id: itemId, group: group, start_time: start_time, end_time: time, address: address, title: item?.mapOrderId });
        dispatch(setTimelineSelected({ ...selected, id: itemId, group: group, start_time: start_time, end_time: time, address: address, title: item?.mapOrderId }));

        return {
          ...item,
          id: itemId,
          itemProps: {
            style: {
              background: color
            }
          },
          group: group,
          start_time: start_time,
          end_time: time,
        };
      }
      return item;
    });
    updateItemsCount(newItems)
    setItems(newItems);
    setJobItems(newItems)
  }

  const [sourceGroupId, setSourceGroupId] = useState(null);
  const [destinationGroupId, setDestinationGroupId] = useState(null);


  const updateJobs2 = (id, vehicleId, start_time, end_time, timeGap, groupType, jobsList) => {
    let color = '#000000';
    let time = end_time;
    let address = '';

    if (vehicleId === 'unassigned') {
      const temp = jobsList.map(job => {
        if (job?.id === id) {
          address = job?.orderID[0]?.orderAddress;
          const itemProps = {
            style: {
              background: color
            }
          };
          return { ...job, vehicle: null, itemProps: itemProps, vehicleID: 'unassigned', service_time: null };
        }
        return job;
      });
      return { color, time, address, updatedJobs: temp };
    } else {
      const vehicle = vehicles?.filter(item => item?.routeSettings?.availability === "available" && item?.driver)
        .find(item => item?.id === vehicleId);
      color = vehicle?.color;
      const service_time_vehicle = vehicle?.routeSettings?.speed;
      const temp = jobsList.map(job => {
        if (job?.id === id) {
          address = job?.orderID[0]?.orderAddress;
          const itemProps = {
            style: {
              background: color
            }
          };
          time = Number(start_time) + (groupType === "unassigned" ? Number(serviceTime) * 1000 : timeGap);
          const timeFrame = convertTime(start_time) + ' - ' + convertTime(time);
          return { ...job, vehicle, itemProps, timeFrame, vehicleID: vehicleId, service_time: service_time_vehicle || 10 };
        }
        return job;
      });
      return { color, time, address, updatedJobs: temp };
    }
  };


  // Function to swap all items between two groups and update jobs
  const swapAllItemsBetweenGroups = (sourceGroupId, destinationGroupId) => {
    // Get all items in the source and destination groups
    const sourceGroupItems = items.filter((item) => item.group === sourceGroupId);
    const destinationGroupItems = items.filter((item) => item.group === destinationGroupId);

    // Create a copy of the items array to prevent mutation
    let updatedItems = [...items];
    let allUpdatedJobs = [...jobs]; // To accumulate all job updates

    // Swap the source group items to the destination group
    const updatedSourceGroupItems = sourceGroupItems.map((item) => {
      const newGroup = destinationGroupId;

      // Update jobs for the source items and accumulate updated jobs
      const { color, time, address, updatedJobs } = updateJobs2(
        item.id,
        newGroup,
        item.start_time,
        item.end_time,
        item.end_time - item.start_time,
        item.group,
        allUpdatedJobs // Pass current jobs to ensure all updates are reflected
      );

      // Update the job list
      allUpdatedJobs = updatedJobs;

      return {
        ...item,
        group: newGroup,
        itemProps: {
          ...item.itemProps,
          style: {
            ...item.itemProps.style,
            background: color
          }
        },
        end_time: time,
        address: address
      };
    });

    // Swap the destination group items to the source group
    const updatedDestinationGroupItems = destinationGroupItems.map((item) => {
      const newGroup = sourceGroupId;

      // Update jobs for the destination items and accumulate updated jobs
      const { color, time, address, updatedJobs } = updateJobs2(
        item.id,
        newGroup,
        item.start_time,
        item.end_time,
        item.end_time - item.start_time,
        item.group,
        allUpdatedJobs // Pass current jobs to ensure all updates are reflected
      );

      // Update the job list
      allUpdatedJobs = updatedJobs;

      return {
        ...item,
        group: newGroup,
        itemProps: {
          ...item.itemProps,
          style: {
            ...item.itemProps.style,
            background: color
          }
        },
        end_time: time,
        address: address
      };
    });

    // Replace the items in updatedItems with updatedSourceGroupItems and updatedDestinationGroupItems using id
    [...updatedSourceGroupItems, ...updatedDestinationGroupItems].forEach((updatedItem) => {
      const index = updatedItems.findIndex((item) => item.id === updatedItem.id);
      if (index !== -1) {
        updatedItems[index] = updatedItem;
      }
    });

    // Dispatch the updated job list after all updates
    dispatch(setJobs(allUpdatedJobs));

    // Update items
    setMainUpdatedItems(updatedItems);
    updateItemsCount(updatedItems); // Optional: If you need to update the count of items
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (mapOrder) {
      const selected = items.find((item) => item.id === mapOrder);
      setSelected(selected);
    }
  }, [mapOrder])

  useEffect(() => {
    setItems(mainUpdatedItems)
    setJobItems(mainUpdatedItems)
    updateItemsCount(mainUpdatedItems)

    // Create time slot state array
    const timeSlotData = mainUpdatedItems.map((item) => ({
      extraTitle: item.extraTitle,
      start_time: item.start_time,
      end_time: item.end_time,
    }));

    // Update time slot state with new array
    setTimeSlotState(timeSlotData);
  }, [mainUpdatedItems])

  return (
    <div className={classes.timelineWidth} >
      <Timeline
        sidebarWidth={400}
        groups={groups}
        stackItems={true}
        items={items}
        selected={mapOrder ? [mapOrder] : []}
        defaultTimeStart={startDate}
        defaultTimeEnd={endDate}
        //remove these tow for zoom
        visibleTimeStart={startDate}
        visibleTimeEnd={endDate}
        onItemSelect={(itemId, e, time) => {
          const selected = items.find((item) => item.id === itemId);
          setSelected(selected);
          dispatch(setTimelineSelected(selected));
        }}
        onItemDeselect={(itemId, e, time) => {
          dispatch(setTimelineSelected(null));
          setSelectedOrderMap(null)
        }}
        onItemMove={(itemId, dragTime, newGroupOrder) => itemMover(itemId, dragTime, newGroupOrder)}
        itemRenderer={itemRenderer}

      >
        <DateHeader />
        <TodayMarker />
        <CursorMarker />
      </Timeline>

      <Grid m={1}>
        <IconButton
          onClick={handleOpen}
          sx={{
            backgroundColor: '#1976d2', // Button background color
            color: '#fff', // Icon color
            borderRadius: '8px', // Slightly rounded corners
            padding: '10px 20px', // Padding for the button
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            display: 'flex', // Flex layout to align items
            alignItems: 'center', // Center items vertically
            '&:hover': {
              backgroundColor: '#1565c0', // Darker blue on hover
            },
          }}
        >
          <SwapHorizIcon sx={{ marginRight: '8px' }} /> {/* Space between icon and text */}
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Transfer Routes Between Drivers
          </Typography>
        </IconButton>

        {/* Modal for swapping items */}
        <SwapItemsModal
          open={open}
          handleClose={handleClose}
          groups={groups}
          swapAllItemsBetweenGroups={swapAllItemsBetweenGroups}
        />
      </Grid>

      {console.log("selected in timeline", selected)}

      {selected && (
        <div style={{ margin: "1rem" }}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>

                <h4>
                  {`${selected?.customerName} | ${selected?.mapOrderId ?? (selected?.title || selected?.extraTitle)} | ${selected?.type} | ${selected?.address}`}{!selected.timeSlot ? ` | Time Window: Not applicable` : `  | Time Window: ${convertTime(selected?.start_time)?.toString()} - ${convertTime(selected?.end_time)?.toString()}`}{selected?.timeSlot && ` | Scheduled Time Slot: ${selected.timeSlot}`}{!selected.timeSlot && ` | Scheduled Time Slot: ${selected.timeFrame}`}
                </h4>


              </Grid>
            </CardContent>
          </Card>
        </div>
      )}
    </div >
  );
}
