import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, TextField, CardHeader, Card } from '@mui/material';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LinearProgress from '@mui/material/LinearProgress';
import AlertInlineMain from "components/common/AlertInlineMain";
import { useStyles } from 'utils/styles/styles';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className='icon' />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className='icon' />;
}

export default function CustomSortIcons({
  columns,
  fetchDataUrl,
  status,
  buttonDisabled,
  title,
  newData,
  role,
  height,
  refreshData,
  fixHeight,
  rangeObject,
  timeSlotState
}) {
  const axios = useAxiosPrivate()
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);

    if (text) {
      // eslint-disable-next-line
      const result = rows.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      // setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(rows);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, fetchDataUrl, refreshData, timeSlotState]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled]);


  useEffect(() => {
    if (newData) {
      setFilteredItems(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);


  const loadData = async () => {
    try {
      setLoading(true);
      setRows([]);
      const { data } = await axios.get(fetchDataUrl);
      //set hardcode value for customer report
      const url = window.location.pathname.split('/');
      const path = url[1];

      // Retrieve orders from local storage
      const localOrders = JSON.parse(localStorage.getItem('orders')) || [];

      // Map local orders by orderId for quick access
      const localOrdersMap = localOrders.reduce((acc, order) => {
        acc[order.orderId] = order;
        return acc;
      }, {});

      let processedData = data.map(item => {
        // Check for matching local order
        const localOrder = localOrdersMap[item.orderId];

        // Replace values from localOrders if they exist
        if (localOrder) {
          return {
            ...item,
            orderType: localOrder.orderType || item.orderType,
            pickupTimeSlot: localOrder.pickupTimeSlot || item.pickupTimeSlot,
            orderAddress: localOrder.orderAddress || item.orderAddress,
            orderNote: localOrder.orderNote || item.orderNote,
            pickupNote: localOrder.pickupNote || item.pickupNote,
            orderStatus: localOrder.orderStatus || item.orderStatus,
            orderLocation: localOrder.orderLocation || item.orderLocation,
          };
        }
        return item;
      });

      // Check if timeSlotState exists and has items, then append timestart and timeend to matching orderId in data
      if (timeSlotState && timeSlotState.length > 0) {
        processedData = processedData.map(item => {
          const matchingSlot = timeSlotState.find(slot => slot.extraTitle === item.orderId);
          if (matchingSlot) {
            return {
              ...item,
              timestart: matchingSlot.start_time,
              timeend: matchingSlot.end_time,
            };
          }
          return item;
        });
      }

      // Filter and set rows based on role and path
      if (path === 'customer-reports') {
        setFilteredItems(processedData.filter(item => rangeObject ? isDateInRange(item?.pickupDate, rangeObject) : true));
        setRows(processedData.filter(item => rangeObject ? isDateInRange(item?.pickupDate, rangeObject) : true));
      } else {
        if (role === 'franchisee' || role === 'corporate' || role === 'manager') {
          const statusTrueRows = processedData.filter(item => rangeObject ? isDateInRange(item?.pickupDate, rangeObject) : true)
            .filter(item => item?.item ? item?.item?.status === true : !item?.item);
          setFilteredItems(statusTrueRows);
          setRows(statusTrueRows);
        } else {
          setFilteredItems(processedData.filter(item => rangeObject ? isDateInRange(item?.pickupDate, rangeObject) : true));
          setRows(processedData.filter(item => rangeObject ? isDateInRange(item?.pickupDate, rangeObject) : true));
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      if (error.response?.status === 401) {
        if (error.response?.data.message) {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: `${error.response.data.message}`,
          });
          setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
        } else {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Authorized Access!',
          });
          setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
        }
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  function isDateInRange(dateToCheck, rangeObject) {
    const startDate = new Date(rangeObject.startDate);
    const endDate = new Date(rangeObject.endDate);
    const date = new Date(dateToCheck);

    return date >= startDate && date <= endDate;
  }

  return (
    <Grid container className={classes.root1} spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Grid container item
        alignItems="center"
        justifyContent="center">

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >

          <Card>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title={`${title.toUpperCase()}`} />
            <Grid container spacing={2}>
              {' '}
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  label='Search'
                  fullWidth
                  variant='outlined'
                  style={{ margin: '1rem' }}
                  onChange={onSearch}
                  value={searchText}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{ height: !height ?? '60vh', margin: '1rem', padding: 0 }}
            >
              <DataGrid
                getRowHeight={() => !fixHeight ? 'auto' : ''}
                autoHeight
                columns={columns}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                loading={loading}
                disableDensitySelector={true}
                rows={filteredItems}
                components={{
                  Toolbar: GridToolbar,
                  ColumnSortedDescendingIcon: SortedDescendingIcon,
                  ColumnSortedAscendingIcon: SortedAscendingIcon,
                  LoadingOverlay: LinearProgress,
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          columnField: 'price',
                          operatorValue: '>',
                          value: '2.5',
                        },
                      ],
                    },
                  },
                }}
              />
            </Grid>
            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
