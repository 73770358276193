import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Select, MenuItem, Typography, Box } from '@mui/material';

const SwapItemsModal = ({ open, handleClose, groups, swapAllItemsBetweenGroups }) => {
    const [sourceGroupId, setSourceGroupId] = useState('');
    const [destinationGroupId, setDestinationGroupId] = useState('');

    const handleSwap = () => {
        swapAllItemsBetweenGroups(sourceGroupId, destinationGroupId);
        handleClose(); // Close the modal after swapping
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '20px', // Rounded corners
                    padding: '20px', // Padding for more modern look
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Soft shadow for a floating effect
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h5" align="center" fontWeight="bold" color="primary">
                    Transfer Routes between Drivers
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            Choose groups to swap the items between
                        </Typography>
                    </Grid>

                    {/* Source Group Select */}
                    <Grid item xs={6}>
                        <Box sx={{ borderRadius: '8px', overflow: 'hidden' }}>
                            <Select
                                value={sourceGroupId}
                                onChange={(e) => {
                                    setSourceGroupId(e.target.value);
                                    // Reset destination group if it matches source
                                    if (destinationGroupId === e.target.value) {
                                        setDestinationGroupId('');
                                    }
                                }}
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '8px',
                                    '& fieldset': { borderColor: '#ddd' }, // Light border
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select Source Group
                                </MenuItem>
                                {groups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>

                    {/* Destination Group Select */}
                    <Grid item xs={6}>
                        <Box sx={{ borderRadius: '8px', overflow: 'hidden' }}>
                            <Select
                                value={destinationGroupId}
                                onChange={(e) => setDestinationGroupId(e.target.value)}
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '8px',
                                    '& fieldset': { borderColor: '#ddd' }, // Light border
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select Destination Group
                                </MenuItem>
                                {groups
                                    .filter((group) => group.id !== sourceGroupId) // Exclude selected source group
                                    .map((group) => (
                                        <MenuItem key={group.id} value={group.id}>
                                            {group.title}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center', paddingBottom: '20px' }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        padding: '10px 20px',
                        borderColor: '#ff5252',
                        color: '#ff5252',
                        fontWeight: 'bold',
                        borderRadius: '30px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#ffeaea',
                            borderColor: '#ff5252',
                        },
                    }}
                >
                    Cancel
                </Button>

                <Button
                    onClick={handleSwap}
                    variant="contained"
                    disabled={!sourceGroupId || !destinationGroupId} // Disable when both not selected
                    sx={{
                        padding: '10px 20px',
                        backgroundColor: !sourceGroupId || !destinationGroupId ? '#ccc' : '#1976d2', // Grey out when disabled
                        color: '#fff',
                        fontWeight: 'bold',
                        borderRadius: '30px',
                        textTransform: 'none',
                        marginLeft: '15px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            backgroundColor: !sourceGroupId || !destinationGroupId ? '#ccc' : '#1565c0',
                        },
                    }}
                >
                    Transfer Routes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SwapItemsModal;
