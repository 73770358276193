import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DataGridTable from 'components/layouts/DataGrid';
import Chip from '@mui/material/Chip';
import OrderView from '../../screens/orders/OrderView';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertMain from "components/common/AlertMain";
import moment from "moment";
import EditOrderView from 'screens/orders/EditOrderView';

export default function OrderManagement({ dateVal, setUpdateOrders, updateOrders, setReqNumber, onDeleteSuccess, timeSlotState, onUpdateSuccess }) {
  const [status, setStatus] = useState(false);
  //eslint-disable-next-line
  const [type, setType] = useState('pending');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [editData, setEditData] = useState({});
  const axios = useAxiosPrivate();
  const [refreshData, setRefreshData] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  // State for handling the confirmation dialog
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);

  const deleteOrder = async (id) => {
    try {
      await axios.delete(`order/manualorder/${id}`);
      setReqNumber();
      onDeleteSuccess();
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Order Deleted Successfully',
      });
      setRefreshData(!refreshData);
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
      }, 3000);
    } catch (error) {
      if (error.response.data) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: error.response.data.message,
        });
      }
      setRefreshData(!refreshData);
    }
  };

  const handleDelete = (id) => {
    setOrderIdToDelete(id);  // Store the ID of the order to be deleted
    setConfirmDeleteOpen(true); // Open confirmation dialog
  };

  const handleConfirmDelete = async () => {
    await deleteOrder(orderIdToDelete); // Proceed with deletion
    setConfirmDeleteOpen(false); // Close confirmation dialog
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false); // Close confirmation dialog without deleting
  };

  useEffect(() => {
    setStatus(!status);
    //eslint-disable-next-line
  }, [dateVal]);

  const convertTime = (time) => new Date(time).toLocaleString('en-US', { timeStyle: "short", hour12: true });

  const columns = [
    { field: 'orderId', headerName: 'Order ID', minWidth: 100, flex: 0.2 },
    {
      field: 'customer',
      headerName: 'Customer Name',
      minWidth: 150,
      flex: 0.5,
      valueGetter: (params) => {
        return `${params.row.customer?.firstName || ''} ${params.row.customer?.lastName || ''}`;
      },
    },
    {
      field: 'orderStatus',
      headerName: 'Order Status',
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => {
        switch (params.row.orderStatus) {
          case 'pending':
            return <Chip label='Pending' color='primary' />;
          case 'process':
            return <Chip label='Processing' color='secondary' />;
          case 'completed':
            return <Chip label='Completed' color='success' />;
          default:
            return <Chip label='Pending' color='primary' />;
        }
      },
    },

    {
      field: 'orderType',
      headerName: 'Services',
      minWidth: 290,
      flex: 0.6,
      valueGetter: (params) => {
        if (params.row.isManualOrder) {
          return "Manual Order"
        } else {
          switch (params.row.orderType) {
            case '1':
              return 'Wash/Dry/Fold';
            case '2':
              return 'Dry Cleaning';
            case '3':
              return 'Tailoring';
            case '4':
              return 'Dry Cleaning & Tailoring';
            case '5':
              return 'Wash/Dry/Fold , Tailoring';
            case '6':
              return 'Wash/Dry/Fold , Tailoring';
            case '7':
              return 'Wash/Dry/Fold , Dry Cleaning';
            case '8':
              return 'Wash/Dry/Fold , Dry Cleaning';
            case '9':
              return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
            case '10':
              return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
            default:
              return '-';
          }
        }
      },
    },
    {
      field: 'Time',
      headerName: 'Time',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        // Get the orderId from the row
        const timestart = params?.row?.timestart;
        const timeend = params?.row?.timeend;

        if (timestart && timeend) {
          return `${convertTime(timestart)?.toString()} - ${convertTime(timeend)?.toString()}`
        }

        // If no match or orderId doesn't exist, return "Not applicable"
        return "Not applicable";
      },
    },
    {
      field: 'pickupDate',
      headerName: 'Pickup Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let pickupDate = params?.row?.pickupDate
        if (pickupDate?.includes("Z")) {
          pickupDate = (pickupDate?.split("T")[0])
        } else {
          pickupDate = params?.row?.pickupDate
        }
        return `${params?.row?.pickupDate ? moment(pickupDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'pickupTimeSlot',
      headerName: 'Pickup TimeSlot',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        return `${params?.row?.pickupTimeSlot ? params?.row?.pickupTimeSlot : "Not applicable"}`;
      },
    },
    {
      field: 'diliverDate',
      headerName: 'Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let diliverDate = params?.row?.diliverDate
        if (diliverDate?.includes("Z")) {
          diliverDate = (diliverDate?.split("T")[0])
        } else {
          diliverDate = params?.row?.diliverDate
        }
        return `${params?.row?.diliverDate ? moment(diliverDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'diliverTimeSlot',
      headerName: 'Delivery TimeSlot',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        return `${params?.row?.diliverTimeSlot ? params?.row?.diliverTimeSlot : "Not applicable"}`;
      },
    },
    {
      field: 'specialDate',
      headerName: 'Special Care Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let specialDate = params.row.specialDate
        if (specialDate?.includes("Z")) {
          specialDate = (specialDate?.split("T")[0])
        } else {
          specialDate = params.row.specialDate
        }
        return `${params?.row?.specialDate ? moment(specialDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      minWidth: 100,
      flex: 0.2,
      disableExport: true,
      renderCell: (params) => {
        const onClickStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setEditData(params.row);
          setOpen2(true);
          setStatus(!status);
        };

        return <Button onClick={onClickStatus}>Edit</Button>;
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      minWidth: 100,
      flex: 0.2,
      disableExport: true,
      renderCell: (params) => {
        const onClickStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          handleDelete(params.row.id); // Show confirmation dialog
        };
        return <Button onClick={onClickStatus}>Delete</Button>;
      }
    }
  ];

  return (

    <Grid container
    // spacing={1}
    // md={12} xl={12}
    >
      {/* <Grid container item 
            // spacing={1}
             xs={6} md={6} xl={6}></Grid> */}
      <Grid item xs={12} md={12} xl={12}>
        <DataGridTable
          // title={`${type.toUpperCase()} Orders`}
          height={"35rem"}
          title={`Orders`}
          fetchDataUrl={`/job/frontend-orders-date/${dateVal}`}
          // fetchDataUrl={`/orders/orders-date/${dateVal}`}
          columns={columns}
          timeSlotState={timeSlotState}
          status={status}
          refreshData={refreshData}
        />
      </Grid>
      <OrderView
        setUpdateOrders={setUpdateOrders}
        editData={editData}
        setStatus={setStatus}
        status={status}
        open={open}
        setOpen={setOpen}
      />
      <EditOrderView
        setUpdateOrders={setUpdateOrders}
        editData={editData}
        setStatus={setStatus}
        updateOrders={updateOrders}
        status={status}
        open={open2}
        setOpen={setOpen2}
        onUpdateSuccess={onUpdateSuccess}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>This action will clear the in-progress route build. Are you sure you want to delete this order?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">OK</Button>
        </DialogActions>
      </Dialog>

      <AlertMain setAlert={setAlert} alert={alert} />
    </Grid>
  );
}
